import request from '@/utils/request'


// 查询等级设置列表
export function listLevel(query) {
  return request({
    url: '/user/level/list',
    method: 'get',
    params: query
  })
}

// 查询等级设置分页
export function pageLevel(query) {
  return request({
    url: '/user/level/page',
    method: 'get',
    params: query
  })
}

// 查询等级设置详细
export function getLevel(data) {
  return request({
    url: '/user/level/detail',
    method: 'get',
    params: data
  })
}

// 新增等级设置
export function addLevel(data) {
  return request({
    url: '/user/level/add',
    method: 'post',
    data: data
  })
}

// 修改等级设置
export function updateLevel(data) {
  return request({
    url: '/user/level/edit',
    method: 'post',
    data: data
  })
}

// 删除等级设置
export function delLevel(data) {
  return request({
    url: '/user/level/delete',
    method: 'post',
    data: data
  })
}
