<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择用户</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.手机号')" prop="mobile">
                <a-input v-model="queryParam.mobile"  @pressEnter="handleQuery":placeholder="$t('通用.输入.请输入')+$t('用户.手机号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.邮箱')" prop="email">
                <a-input v-model="queryParam.email" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('用户.邮箱')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.昵称')" prop="nickName">
                <a-input v-model="queryParam.nickName" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('用户.昵称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.性别')" prop="sex">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.性别')" v-model="queryParam.sex" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in customDict.SexEnum" :key="index" :value="d.type">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.用户等级')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.用户等级')" v-model="queryParam.levelId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in levelList" :key="index" :value="d.id">{{ d.levelName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.SexEnum" :value="record.sex"/>
        </span>
<!--        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>-->
        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" @click="handleRecordPreview(record.avatar)" alt="none"/>
          </div>
        </template>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        :pageSizeOptions="pageSizeOptions"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import { pageUser,listUser, delUser } from '@/api/biz/user'
import { tableMixin } from '@/store/table-mixin'
import {listLevel} from "@/api/user/level";

export default {
  name: 'CheckboxGoodsSelectForm',
  props: {},
  components: {
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      levelList: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      limitNum: undefined,
      selectedRowKeys: [],
      selectedRows: [],
      categoryList: [],
      cateOptions: [],
      brandList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickName: null,
        sex: undefined,
        invitationCode: null,
        isNotice: null,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('用户.手机号'),
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: this.$t('用户.邮箱'),
          dataIndex: 'email',
          align: 'center'
        },
        {
          title: this.$t('用户.昵称'),
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.头像'),
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          align: 'center'
        },
        {
          title: this.$t('用户.真实姓名'),
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.微信'),
          dataIndex: 'weChat',
          ellipsis: true,
          align: 'center'
        },
        {
          title: "qq",
          dataIndex: 'qq',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.用户等级'),
          dataIndex: 'levelName',
          align: 'center'
        },
        {
          title: this.$t('用户.性别'),
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          align: 'center'
        },
        {
          title:  this.$t('用户.生日'),
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('用户.用户状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('用户.注册时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    };
  },
  filters: {},
  created() {
    this.getLevelList()
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.resetQuery()
    },
    reload() {
      this.selectedRowKeys = [];
      this.selectedRows=[];
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /**
     *
     * @param records
     * @param limitNum 限制选择的条数, 如果传null 或者undefined 则不限制
     */
    openUser(records, limitNum) {
      if (limitNum) {
        this.limitNum= limitNum
      }
      if (records) {
        console.log('带过来的key',records)
        this.selectedRows = records;
        this.selectedRowKeys = this.selectedRows.map(item => item.id);
        this.queryParam.orderByGoodsIdList = this.selectedRowKeys;
      }
      this.getList()
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('userSelect', this.selectedRows)
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList () {
      this.loading = true
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam = {
        mobile: null,
        email: null,
        nickName: null,
        sex: undefined,
        invitationCode: null,
        isNotice: null,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      };
      this.handleQuery();
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelect (record, selected, selectedRows) {
      //如果是选中,则判断是限制数量
      if (selected) {
        if (this.limitNum) {
          if (this.selectedRows.length >= this.limitNum) {
            console.log('到达了限制数量,',this.limitNum)
            return false
          }
        }
        this.selectedRows.push(record)
      } else {
        this.selectedRows = this.selectedRows.filter(e => e.id !== record.id);
      }
      this.ids = this.selectedRows.map(item => item.id)
      this.selectedRowKeys = this.ids;
    },
    onSelectAll(selected, selectedRows, changeRows) {
      if (this.limitNum) {
        //如果有限制数量,不允许全选
        return false
      }
      if (selected) {
        this.selectedRows =this.selectedRows.concat(changeRows)
      } else {
        let selectIds = changeRows.map(e => e.id);
        this.selectedRows = this.selectedRows.filter(e => !selectIds.includes(e.id));
      }
      this.ids = this.selectedRows.map(item => item.id)
      this.selectedRowKeys = this.ids;
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    getLevelList() {
      listLevel().then(response => {
        this.levelList = response.data
      })
    },
  }
}
</script>
